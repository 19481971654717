import React, { useEffect, useState } from "react";
import JoinScreen, { INPUT_ID } from "../../../screens/join/JoinScreen";
import useHandleJoin from "./useHandleJoin";
import useHandleTryAgain from "./useTryAgain";
import useHandleSignIn from "./useHandleSignIn";

const JoinEventController = ({ publicIdFromUrl }) => {
  const [state, setState] = useState(INPUT_ID);
  const [publicId, setPublicId] = useState("");
  const [screenName, setScreenName] = useState();

  const handleJoin = useHandleJoin(setState);
  const handleSignIn = useHandleSignIn(setState);
  const handleTryAgain = useHandleTryAgain(setState, setPublicId);

  useEffect(() => {
    if (publicIdFromUrl) {
      setPublicId(publicIdFromUrl);
      handleJoin(publicIdFromUrl);
    }
  }, [publicIdFromUrl]);

  return (
    <JoinScreen
      isEvent
      state={state}
      publicId={publicId}
      onPublicIdChange={setPublicId}
      onJoin={handleJoin}
      screenName={screenName}
      onScreenNameChange={setScreenName}
      onSignIn={handleSignIn}
      onTryAgain={handleTryAgain}
    />
  );
};

export default JoinEventController;
