import {
  MAX_PLAYERS_ERROR,
  UNKNOWN_ERROR,
} from "../../../screens/join/JoinScreen";
import Bugsnag from "@bugsnag/js";
import { useCallback } from "react";
import { navigate } from "gatsby-link";
import useSignInEventMutation from "../../../hooks/mutations/useSignInEventMutation";

const useHandleSignIn = (setState) => {
  const [signInEventMutation] = useSignInEventMutation();

  return useCallback(async (screenName) => {
    try {
      await signInEventMutation({
        variables: {
          name: screenName,
        },
      });
      navigate("/preview-av/?next=event&selfie=true");
    } catch (error) {
      Bugsnag.notify(error);
      if (
        error.graphQLErrors[0].message.startsWith(
          "Maximum participants reached"
        )
      ) {
        setState(MAX_PLAYERS_ERROR);
      } else {
        setState(UNKNOWN_ERROR);
      }
    }
  }, []);
};

export default useHandleSignIn;
