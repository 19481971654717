import { INPUT_ID } from "../../../screens/join/JoinScreen";
import { useCallback } from "react";

const useHandleTryAgain = (setState, setPublicId) =>
  useCallback(async () => {
    setPublicId("");
    setState(INPUT_ID);
  }, []);

export default useHandleTryAgain;
