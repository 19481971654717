import { gql, useMutation } from "@apollo/client";

const SIGN_IN_EVENT = gql`
  mutation SignInEvent($name: String!) {
    signInEvent(name: $name) {
      ok
    }
  }
`;

const useSignInEventMutation = () => useMutation(SIGN_IN_EVENT);

export default useSignInEventMutation;
