import { gql, useMutation } from "@apollo/client";

const JOIN_EVENT = gql`
  mutation JoinEvent($publicEventId: String!) {
    joinEvent(publicEventId: $publicEventId) {
      event {
        id
        name
      }
    }
  }
`;

const useJoinEventMutation = () => useMutation(JOIN_EVENT);

export default useJoinEventMutation;
