import {
  INVALID_ID,
  JOINING,
  SIGN_IN,
  UNKNOWN_ERROR,
} from "../../../screens/join/JoinScreen";
import sleep from "../../../../util/sleep";
import Bugsnag from "@bugsnag/js";
import { useCallback } from "react";
import useJoinEventMutation from "../../../hooks/mutations/useJoinEventMutation";
import { get } from "lodash";

const useHandleJoin = (setState) => {
  const [joinEventMutation] = useJoinEventMutation();

  return useCallback(async (publicEventId) => {
    setState(JOINING);
    await sleep(1000);
    try {
      await joinEventMutation({
        variables: {
          publicEventId,
        },
      });
      setState(SIGN_IN);
    } catch (error) {
      Bugsnag.notify(error);

      const message = get(error, "graphQLErrors.0.message");
      if (message) {
        if (message.startsWith("No such event")) {
          setState(INVALID_ID);
        } else {
          setState(UNKNOWN_ERROR);
        }
      } else {
        setState(UNKNOWN_ERROR);
      }
    }
  }, []);
};

export default useHandleJoin;
